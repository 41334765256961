<template>
  <div class="wrapper">
    <div class="mb-4">
      <h3>리더십 문항 관리​</h3>
    </div>

    <article>
      <div class="white-box mb-4">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-danger font-bold">* 이미 진행중인 진단이 있을 경우, 진단에 문제가 생길 수 있으니 가급적 변경하지 마시길 바랍니다.</p>
          <div class="d-flex gap-2">
            <b-button variant="outline-primary" @click="goDetail('create', -1)">등록</b-button>
          </div>
        </div>
        <table class="table table-hover text-center mb-4">
          <thead>
            <tr>
              <th>No</th>
              <th>템플릿명</th>
              <th>진단 문항수</th>
              <th>등록일</th>
              <th>HQ매니저</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-if="process.isLoading">
              <td colspan="13">Loading ...</td>
            </tr>
            <tr v-if="!process.isLoading && process.questionList.length === 0">
              <td colspan="13">정보가 없습니다.</td>
            </tr>
            <tr v-else-if="!process.isLoading" v-for="question in process.questionList" :key="question.idx">
              <td>
                <p>{{ question.rowNum }}</p>
              </td>
              <td class="cursor" @click="goDetail('edit', question.idx)">
                <p class="text-primary text-underline">
                  {{ question.question_group_name }}
                </p>
              </td>
              <td>
                <p>
                  {{ question.question_cnt === 0 ? '-' : question.question_cnt }}
                </p>
              </td>
              <td>
                <p>
                  {{ question.createdate }}
                </p>
              </td>
              <td>
                <p>
                  {{ question.creator_name }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <Paging :totaldata="process.pagination.total" :pagingdata="process.params.pageSize" :pagingrange="5" :setPage="process.params.pageNum" @onPagingEvent="process.movePage"></Paging>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { onMounted } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
const store = useStore();

const goDetail = (params, idx) => {
  router.push(`/QuestionManagementCreate?type=${params}${idx === -1 ? '' : '&idx=' + idx}`);
};

const process = reactive({
  isLoading: true,
  params: {
    pageNum: 1,
    pageSize: 50,
  },
  pagination: {
    total: 0,
  },
  questionList: [],
  getQuestionList: async () => {
    process.isLoading = true;
    const result = await _axios.get(`/admin/question/list?pageNum=${process.params.pageNum}&pageSize=${process.params.pageSize}`, {});

    const { success, data, message } = result.data;

    if (success) {
      process.questionList = data.list;
      process.pagination.total = data.total;
    } else {
      alert(message);
    }
    process.isLoading = false;
  },
  movePage: pageNumber => {
    process.params.pageNum = pageNumber;
    process.getQuestionList();
  },
});

onMounted(() => {
  process.getQuestionList();
});
</script>

<style lang="scss" scoped></style>
